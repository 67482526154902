import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OneSignalReact from 'react-onesignal'; // Ensure proper import if needed

const useNotificationListeners = (profile) => {
  const history = useHistory();

  // Extract profile.isLoggedIn to a variable
  const isLoggedIn = profile?.isLoggedIn;

  useEffect(() => {
    if (!isLoggedIn) return;

    const handleNotificationOpened = (notification) => {
      const { data } = notification;
  
      if (data?.notificationType === 'BusinessMessage') {
        history.push(`/messages/${data.businessId || ''}`);
      } else {
        history.push('/');
      }
    };
    
    try {
      console.log('Setting up notification listener...');
      OneSignalReact.addListenerForNotificationOpened((notification) => {
        handleNotificationOpened(notification);
      });
    } catch (error) {
      console.error('Error in Listener For Opened Notification OneSignalReact:', error);
    }
  }, [isLoggedIn, history]);
};

const useSyncUserProfileWithOneSignal = (profile) => {
  useEffect(() => {
    if (!profile) return;

    const syncUserProfile = async () => {
      const { profile: tempPro, isLoggedIn } = profile || {};
      const { sub } = tempPro || {};

      if (!OneSignalReact) {
        console.warn('OneSignalReact SDK not loaded.');
        return;
      }

      try {
        if (isLoggedIn) {
          console.log('Syncing user profile with OneSignalReact...');
          const externalUserId = await OneSignalReact.getExternalUserId();

          if (!sub && externalUserId) {
            console.log('Clearing OneSignalReact user data as no subscription exists.');
            await OneSignalReact.logoutEmail();
            await OneSignalReact.removeExternalUserId();
          } else if (sub && externalUserId !== sub) {
            console.log('Setting OneSignalReact external user ID');
            await OneSignalReact.setExternalUserId(sub);
          } else {
            console.log("OneSignal user already synced or no action needed.");
          }
        } else {
          console.log('User not logged in. Skipping sync.');
        }
      } catch (error) {
        console.error('Error syncing user profile with OneSignalReact:', error);
      }
    };

    syncUserProfile();
  }, [profile]);
};

const NotificationHandler = ({ profile }) => {
  useNotificationListeners(profile);
  useSyncUserProfileWithOneSignal(profile);

  return null; // Component is used for its side effects only
};

export default NotificationHandler;
