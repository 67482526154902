import { prefixHolder } from './prefix-holder';

export const APIPaths = {
    Backend: prefixHolder.Backend,
    Gateway: prefixHolder.Gateway,
    WebAdminFrontend: prefixHolder.WebAdminFrontend,
    QueryPath: `${prefixHolder.Backend}/query`,
    SubscriptionsPath: `${prefixHolder.WebSocket}/query`,
    SignalRPath: `${prefixHolder.Backend}/messages`,
};
