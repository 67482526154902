import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthSlice from './Redux/AuthSlice/AuthSlice';
import ConsumerSlice from 'Redux/ConsumerSlice';
import miscReducer from "Redux/MiscSlice/Misc"
import { combineReducers } from "redux";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth'] //.... iclude those reducers that you dont want to persist
}

const rootReducer = combineReducers({
    auth: AuthSlice,
    misc: miscReducer,
    consumer: ConsumerSlice,
    // businessCategory: businessCategorySlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer
})

export const persistor = persistStore(store)