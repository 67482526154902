import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OneSignalReact from 'react-onesignal';

import { ErrorBoundary } from '@safelyq/base-feature';
import PageTitleContext from 'contexts/PageTitleContext';
import ScrollToTop from 'components/ScrollToTop';
import Routes from 'routes';
import { oneSignalAppId } from 'services/prefix-holder';

import 'bulma.css';
import 'bootstrap.grid.css';

console.disableYellowBox = true;

console.warn = () => { };
console.error = () => { };

const App = () => {
  const [pageTitle, setPageTitle] = useState('');

   // Initialize OneSignalReact and set up notification listeners
   useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        console.log('Initializing OneSignalReact...');
        OneSignalReact.init({ appId: oneSignalAppId });
        OneSignalReact.showSlidedownPrompt();

        console.log('OneSignalReact initialized successfully.');
      } catch (error) {
        console.error('Error initializing OneSignalReact:', error);
      }
    };

    initializeOneSignal();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'cookieyes';
    script.src = 'https://cdn-cookieyes.com/client_data/6c61cb2cdc2257f305eaea5e/script.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);
  
  return (
    <ErrorBoundary>
      <Helmet>
        <title>SafelyQ</title>
        <meta name='description' content='SafelyQ, a IT Best Solutions INC subsidiary, was formed to help business, hospitals, counties, cities, large  gatherings of any kind to manage people traffic in and out of facilities open or closed.' />
        <meta name='keywords' content='Appointments, SafelyQ Appointment, Online Appointments, Queueing System, Le Bernardin Appointment, Dallas City Hall' />
      </Helmet>
      <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes />
        </BrowserRouter>
      </PageTitleContext.Provider>
    </ErrorBoundary>
  );
};

export default App;
