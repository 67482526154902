import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

import Queries from 'components/Common/Queries';
import { UserRoutes } from './UserRoutes';
import { useProfile } from 'hooks/useProfile';
import { adduser } from 'Redux/AuthSlice/AuthSlice';
import FullPageLoader from 'sharedComponents/FullPageLoader/FullPageLoader';
import NotificationHandler from './UserRoutes/NotificationHandler';

const Routes = () => {
  const { pathname } = useLocation();

  const { loading, data } = useQuery(Queries.GET_USER_IS_PROFILE_COMPLETE);

  const profile = useProfile();
  const { login, oidcUser } = profile;

  // redirect to the login and than home if the user is already logged in
  const loggedInLocal = localStorage.getItem("authPersist")
  const isLoggingInForPayment = localStorage.getItem("userLoggingInForPayment") === "true"; // payment: fetching email via login

  useEffect(() => {
    if (loggedInLocal === "true") {
      // do not allow auto login unless user clicks on the login on payment screen
      if (pathname === "/payment") {
        isLoggingInForPayment && login(); // user has clicked on the login
      }
      else {
        login();
      }

    }
  }, [])

  // update persist true
  useEffect(() => {
    if (oidcUser?.access_token) {
      localStorage.setItem("authPersist", "true")
    }
  }, [oidcUser?.access_token])

  const dispatch = useDispatch()
  if (data) {
    const userdata = {
      id: data.getUser.user?.id,
      email: data.getUser.user?.email,
      firstName: data.getUser.user?.firstName,
      lastName: data.getUser.user?.lastName,
      name: data.getUser.user?.name,
      isSuperAdmin: data.getUser.user?.isSuperAdmin,
      totalNotifications: data.isProfileComplete.unviewedCount,
      unreadCount: data.getUser.user?.conversationsSummary.unreadCount,
      isAuthenticated: data.isProfileComplete.isAuthenticated,
      isCompleted: data.isProfileComplete.isCompleted,
      isPhoneNumberConfirmed: data.isProfileComplete.isPhoneNumberConfirmed,
    }
    const obj = {
      authinfo: profile.oidcUser,
      userinfo: {
        ...userdata
      }
    }
    dispatch(adduser(obj));
  }

  return (
    <div>
      <NotificationHandler profile={profile} />
      {loading && <FullPageLoader />}
      <Switch>
        <UserRoutes profile={profile} />
      </Switch>
    </div>

  );
};

export default Routes;
